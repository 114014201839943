	
	// Navigation interaction
	// Loading, appear, disappear, etc

	//////////////////////////////////
	// - Interaction via body states
	//////////////////////////////////

	body{
			
		// Loading	

		$LogoLetters:(  

            // CARAVANSERAIL 
            // Letters order is messed up
            // for better shine effect

            'A-1',
            'C', 
            'E',
            'A-1-1' ,
            'V', 
            'S',
            'R-1',
            'N',
            'I', 
            'R',
            'A-3',
            'L',
            'A-2' 
            
        ); 

        $Speed : 0.3s;
        $SpeedIncrement : .2s;
        $Delay : 0s;
        $DelayIncrement : .3s; 	

		.LoadingHolder{  

		 	height:100vh;
	        width:100vw;
	        z-index:1000000;
	        background:$white;
	        position:fixed;

	        top:0;
	        left:0; 
	        display:flex;
	        justify-content:center;
	        flex-direction:column;
	        align-items:center;
	        transition:all .4s 1.3s;
	        opacity:1;
 
	        @keyframes LogoAppear {
	            0% {
	                opacity:0; 
	            } 
	            100% {
	                opacity:1; 
	            }
	        }
	        @keyframes LogoDisappear { 
                0% {
                    opacity:1; 
                }
                100% {
                    opacity:0; 
                }  
            } 
	        @keyframes LogoLetters {
	            0% {
	                opacity:0;
	                //fill:$black;
	            }
	            50% {
	                opacity:1;
	                //fill:$black;
	            }
	            100% {
	                opacity:0;
	                //fill:$black;
	            }
	        } 
	        
	        svg{ 
	            max-width:200px;
	            width:100px;
	            height:auto;  
	            margin-bottom:30px;  
	        } // Svg

	    } // LoadingHolder
		
		&.dom-is-loaded{ 
			// border:3px solid green; 
		} // dom is loaded
		
		// When transition out of a page
		// or loading for the first time
		&.is-loading{ 
				
			// Loading Logo state when Loaded
            // Display logo frame
            .LoadingHolder{
            	opacity:1;
            	transition:all .3s 0s;
            	svg{
            		opacity:0;
	           		animation: LogoAppear 1.3s forwards .3s;
	           		.Frame{
	                    opacity:0;  
	                } 
            		/* Make Letters shine */
	                @each $LogoLetter in $LogoLetters{ 
	                    $Delay : $Speed + $DelayIncrement;
	                    $Speed : $Delay + $SpeedIncrement;
	                    /* .#{$LogoLetter}{ animate: LogoLetters 1.3s infinite #{$Delay}; } */
	                     .#{$LogoLetter}{ animation: LogoLetters #{$Speed} infinite #{$Delay}; } 
	                }
            	}
            } 

		} // is-loading

		&:not(.is-loading){
			.LoadingHolder{
	            opacity:0;
	        	pointer-events:none;
	        	svg{
	        		opacity:1;
	                animation: LogoDisappear 0.4s forwards 0.6s;
	                .Frame{
	                    animation: LogoAppear 0.55s forwards 0s;
	                }
	        	}
	        }
		} // is not loading / loaded 
	

		#Navigation{ 
			// aside, nav{
			// 	transform-origin:center;
			// }	
		} 
        &:not(.Navigation_Open){
            #Navigation{
            	transition:all .3s .4s;
                opacity:0;
                pointer-events:none;
                .BG{
                	svg{
                		transition:all .8s .0s; 
                		opacity:0;
                		&.c-top{
	                        top:-5vh;
	                        left:50vw;
	                    }
	                    &.c-bottom{
	                         bottom:-8vh;
	                         left:50vw; 
	                    }
                	}
                }
                aside{
                	opacity:0;
                	transition:all .4s 0s;
                	//transform:scale(1.2);
                }
                nav{
                	transition:all .4s 0s;
                	opacity:0;
					transform:translateX(-100px);
				}
            }
        }
        &.Navigation_Open{
        	header{
        		display:flex;
        	}
            #Navigation{
            	transition:all .3s;
                opacity:1;
                pointer-events:all; 
                .BG{
                	svg{
                		transition:all .6s .3s;   
                		&.c-top{
	                        top:-5vh;
	                        left:12vw!important;
	                    }
	                    &.c-bottom{
	                        bottom:-8vh;
	                        left:30vw; 
	                    }
                	}
                }
                aside{
                	transition:all .3s .3s;
                	opacity:1;
                }
                nav{
                	transition:all .4s .4s;
                	opacity:1;
					transform:translateX(0px);
				}
            }
        }

		////////////////////////////
		// - Barba container
		////////////////////////////

		.barba-container{

			transition:all .6s;
			opacity:1;
			//transform:translateY(0px);
			&.is-loaded{
				// border:3px solid green; 
			} // is-loaded

			&.is-changing-page, &:not(.is-loaded){
				// border:3px solid yellow;
				opacity:0;
				//transform:translateY(50px);
			}

		}  // barba container

	} // body