    
    //  -------------------------------------------------
    //  UI | Interface styles - Styles to re-use
    //     | Components and collections of elements
    //     | Ex : Buttons, lists, forms elements, boxes
    //  -------------------------------------------------
		
	// Type

	// List of custom sizes

		// The following sizes are out of styleguide

			.fs-156{
				font-size:rem(156);
				@include media("<md") {
					font-size:rem(156);
				} 
			}
			.fs-86{
				font-size:rem(86);
				@include media("<md") {
					font-size:rem(50);
				} 
			}
			.fs-76{
				font-size:rem(76);
				@include media("<md") {
					font-size:rem(50);
				} 
			}
			.fs-48{
				font-size:rem(48);
				@include media("<md") {
					font-size:rem(32);
				} 
			}
			.fs-32{
				font-size:rem(32);
				@include media("<md") {
					font-size:rem(28);
				} 
			}
			.fs-24{
				font-size:rem(24);
				@include media("<md") {
					font-size:rem(24);
				} 
			}
			.fs-22{
				font-size:rem(22);
				@include media("<md") {
					font-size:rem(22);
				} 
			}
			.fs-18{
				font-size:rem(18);
				@include media("<md") {
					font-size:rem(18);
				} 
			}
			.fs-16{
				font-size:rem(16);
				@include media("<md") {
					font-size:rem(16);
				} 
			}
			.fs-12{
				font-size:rem(12);
				@include media("<md") {
					font-size:rem(12);
				} 
			}
			 
	// Headings
		
		h1, h2, h3, h4, h5, h6{
			margin-top:0;
			line-height:1em;
			margin-bottom:0.7em;
			small{
				display:block;
				color:currentColor;
			}
		}

	// Paragraphe
    
	p{
		font-family:$fw-book;
		letter-spacing:0.04em;
		line-height:1.5;
		margin-bottom:1.25em; 
	}

	.LastP-NoMargin{
		p:last-of-type{
			margin:0;
		}
	} // LastP-NoMargin

	// Link

	a{
		display:inline-block;
		color:currentColor;
		&:focus, &:active, &:hover{
			text-decoration: none;
			color:currentColor;
		}
		&.BlueHover{
			&:hover{
				color:$blue;
			}
		}
	} // a

	hr{
		border:none;
		display:block;
		height:2px;
		width:100%;
		margin:0 0 1.3rem;
		padding:0;
		background-color:currentColor;
		&.Short{
			max-width:40px;
		}
	} // hr  


	// Breadcrumbs
	
	.Breadcrumbs{
		list-style: none;
		padding:0;
		margin:0; 
		li{
			display:inline-block;
			margin-right:0.5em;
			a{
				position:relative;
				padding-right:0.5em;
				&:before{
					content:"";
					position:absolute;
					top:50%;
					transform:translateY(-50%);
					right:0;
					width:1px;
					height:65%;
					background:$black;
				}
			} // a 
		} // li
	} // breadcrumbs

	.HeroImg{
		min-height:55vh;
		@include media("<md"){
			min-height:calc(100vw * 9 / 16);
		}
	}
	[data-cover]{
		background-size:cover;
		background-position:center center;
	}

	.Dim_16-9{ 
        height:calc(((100vw / 2) - 60px) * (9/16));
    }

	.ThumbWrap{
		&.BG{ 
			
			position:absolute;
			z-index:-1;
			top:50%;
            left:50%;
            transform:translate(-50%, -50%);
			width:100%;
			height:100%;
			background-repeat:no-repeat; 
		}
		img{
			max-width:100%;
			width:100%;
		}
	} // ThumbWrap

	
	// If the list has a class, it's not default
	// Let's kill all those ugly styles
		
		
		ul[class]{
			@include ulReset();
		} // UL with class
	 
	 	// Inline List

	 	.Inline{
			> *{
				display:inline-block;
			}
	 	}

	// If the list does not have a class,
	// Let's redefine those ugly styles
	
		ul:not([class]){
			list-style: none;
			padding:0;
			margin:0;
			margin-bottom:1em;
			font-family: $fw-book;
			li{ 
				padding-left:1.2em;
				position:relative;
				padding-top:0.25em;
				padding-bottom:0.25em;
				&:not(:last-child){
					border-bottom:1px solid $graylight;
				}
				&:before{
					content:"";
					position:absolute;
					top:0.725em;
					left:0.2em;
					background:$gray;
					width:5px;
					height:5px;
					z-index:1; 
				} // before
			} // li
		} // When no class on UL

	.icon{ 
		display:inline-flex;
		vertical-align: middle;
		align-items:center;
		flex-direction:row;
		font-style:normal; 
		span, svg{
			height:1.3em;
			width:auto;
		} 
		
		&:not(.icon-reverse){
			span, svg{
				margin-right:0.4em;
			}
		}
		&.icon-reverse{
			flex-direction:row-reverse;
			span, svg{
				margin-left:0.4em;
			}
		}
		span{
			svg{
				margin:0!important;
			}
		}
	} // icon
 
	dl{ 
		display:flex;
		flex-wrap:wrap; 
		dt, dd{
			display:inline-block;
		}
		dt{
			font-weight:$fw-light;
		}
		dd{ 
			flex-grow:1;
			// width:100%;
		} 
	}
