    
    //  ---------------------------------------------------------
    //  Global | Layout styles  
    //         | Ex : containers, section, headers, footer, menu
    //  ---------------------------------------------------------

    // Setup

    html { font-size:#{$fontbase}px; }


    body{
        font-family:$f-main;
        font-weight:normal;  
        color:$black;
        width:100%;
        height:100%;
        font-size:1rem;
        *, *:focus{
            outline:none!important;
            // Better Font Rendering
            -webkit-font-smoothing: antialiased!important;
            -moz-osx-font-smoothing: grayscale!important;
        }
        svg{
            max-width:100%;
            height:auto;
        }
    } // body 

    // .container{
    //     border:1px solid red;
    // } 

   


    // Helpers

    // Display

    .d-block{
        display:block;
    }
    .d-inlineblock{
        display:inline-block;
    }

    .hidden-desktop{
        @include media(">sm") {
           display:none;
        }
    }
    .hidden-mobile{
        @include media("<md") {
           display:none;
        }
    }

    // Alignment

        .NoFloat{
            float:none;
        }

       // @include media(">sm"){ 

            .Flex_FlexEnd{
                align-self:flex-end;
            }
            .FlexRow{ 
                display:flex;
                flex-direction:row;
            }
            .AlignItems_FlexEnd{
                align-items:flex-end;
            }
            .Flex_SpaceBetween{
                display: flex;
                justify-content:space-between;
            }
 
        //   }

        .align-left{
            text-align:left;
        }
        .align-right{
            text-align:right;
        }
        .align-center{
            text-align:center;
        }
        .align-left-MOB{
            @include media("<md") {
                text-align:left!important;
            }
                
        }
        .fd-underline{
            text-decoration:underline;
        }
        .fs-normal{
            font-style:normal;
        }
        .NoMargin{
            margin:0;
        }
        .NoPadding{
            padding:0;
        }
        .NoMargin-Desktop{
            @include media(">xs") {
                margin:0!important;
            } 
        }
        
        .PosRel{
            position:relative;
        }
        .Low{
            opacity:0.1;
        }
        .Overlay{
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            z-index:-2;
        }

    // Styles

        .fs-italic{
            font-style:italic;
        }

        // Transforms

        .ft-uppercase{
            text-transform:uppercase;
        }
        .ft-lowercase{
            text-transform:lowercase;
        }
        .ft-capitalize{
            text-transform:capitalize;
        }
        .ft-normal{
            text-transform:none;
        }

    // Letter Spacing

        .fs-wide-1{
            letter-spacing: 0.2em;
        }
        .fs-wide-2{
            letter-spacing: 0.21em;
            line-height:1.7;
        }
        .fs-wide-3{
            letter-spacing: 0.1em;
        }
        // Used in large headings
        .fs-wide-4{
            letter-spacing: 0.065em;
        }
        .fs-wide-5{
            letter-spacing: 0.125em;
        }

    .FiligraneHeading{ 
        position:absolute;
        z-index:-1;
        top:calc(50% - 0.3rem);
        left:50%;
        width:100vw;
        overflow-x:hidden;
        height:2em;
        transform:translate(-50%, -50%);
        pointer-events:none;
        span{ 
            display:block;
            position:absolute;
            z-index:-1;
            top:50%;
            left:50%;
            width:300vw;
            transform:translate(-50%, -50%);
        }
    }    

    // SVG Global rules

    svg{
        *{
            fill:currentColor;
        }
    }

    // Main wrap

    #main-wrap, header, footer{ 
        max-width:1700px;
        margin:0 auto;
    }

    header{

        display:flex;
        flex-direction:row;
        align-items:center;
        position:fixed;
        top:0;
        left:50%;
        transform:translateX(-50%);
        z-index:1001;
        width:100%;  
        pointer-events:none;
        transition:padding .3s, box-shadow .3s;
        box-shadow: 0 2px 14px 0 rgba(0,0,0,0);

        nav{ 
            display:flex;
            flex-direction:row;
            align-items:center; 
            pointer-events:all;
            @include media("<md"){
                width:100%;
                justify-content:space-between;
            }

        } // nav 
        p{
            pointer-events:all;
        }
        &.Home{
            display:none;
        }

    } // header

    body:not(.Navigation_Open){
        header{
            &.BeingScrolled{
                border-bottom:1px solid $graylightest;
                background:$white;
                padding:15px 30px!important; 
                box-shadow: 0 2px 14px 0 rgba(0,0,0,0.05);
            }
        }
    }

    footer{ 
      //  display:none;
        // position:absolute;
        // bottom:0;
        // left:50%;
        // transform:translateX(-50%);
        width:100%;
        z-index:1001; 
        pointer-events:none;
    } // footer

    nav{
        .MenuToggle{ 
            letter-spacing:0.2em;
            cursor:pointer;
            svg{
                height:0.85em;
                width:1em;
                &:nth-child(2){
                    display:none;
                }
            } // Svg
            &:not(.icon-reverse){
                svg{
                    margin-right:0.8em;
                }
            }
            &.icon-reverse{
                svg{
                    margin-left:0.8em;
                }
            }
        } // MenuToggle
    }
    
    //

    body{
        
        header{
            z-index:1011;
        }
        &.Home{
            footer{
                position:fixed;
                bottom:0;
                left:50%;
                transform:translateX(-50%);
                width:calc(100vw - 60px);
            }
            header{
                display:none;
            }
        }
    }
    
    // Menu
 
        // Global overlay navigation

        #Navigation{ 
  
            position:fixed;
            height:100vh;
            width:100vw;
            top:0;
            left:0;
            z-index:1010;
            background:$white;
            margin:0;
            padding:0;
            overflow:auto; 

            display:flex;
            flex-direction:row;
            align-items:flex-start;
            
            .Wrap{ 
                width:100%;
                display:flex;
                flex-direction:row;
                align-items:flex-start;
                max-width:1200px;
                margin:0 auto; 
                @include media("<xs"){
                    flex-wrap:wrap;
                }
            } // wrap
        
            aside, nav{
                margin:0;
                width:50%;
                @include media("<xs"){
                    width:100%;
                }
            } // aside nav

            aside{
                flex-grow:1; 
                display:flex;
                flex-direction:column;
                justify-content:space-between;

                @include media("<md"){
                    margin-right:2em;
                }
                @include media("<xs"){ 
                    order: 2;
                }
                a{
                    &:hover{
                        color:$yellow;
                    }
                }
                ul{
                    font-size:rem(22);
                    letter-spacing:0.07em;
                    @include media("<md"){
                        font-size:rem(18);
                    }
                    li{
                        margin-bottom:0.6em;
                    }
                }
            } // aside 

            nav{
                // Logo
                figure{
                    width:10vw; 
                    max-width:145px;
                    min-width:100px;
                    a{
                        display:block;
                    }
                    &:hover{
                        svg{
                            *{
                                fill:$yellow;
                            }
                        }
                    }  
                }
                ul{
                    margin:0;
                    font-size:rem(50);
                    @include media("<lg"){
                        font-size:rem(40);
                    }
                    @include media("<md"){
                        font-size:rem(30);
                    }
                    line-height:1.4;
                    li{
                        &:hover{
                            a{
                                color:$yellow;
                            }
                        }
                    } // li
                } // ul
                @include media("<xs"){ 
                    margin-bottom:30px;
                }
            } // nav
            
            // #Navigation BG
            .BG{
                pointer-events:none;
                position:fixed;
                top:0;
                left:0;
                z-index:-1;
                height:100vh;
                width:100vw;
                overflow:hidden; 
                svg{
                    width:66vh;
                    position:absolute;  
                    *{
                        fill:$graylightest;
                    }
                    
                } // svg
            } // BG - for #Navigation

        } // #Navigation

    // HomeDisplay 

        // *{
        //     border:1px solid red;
        // }

        .HomeDisplay{

            //  border:2px solid blue;
            
            // transform:translateY(-70px);
            // margin-bottom:-70px;
            @include media(">sm") { 

                padding-right:0;
                // margin-bottom:70px;  
                display:flex;
                flex-direction:row;
                justify-content:space-between;
                align-items:top; 
            }
            @include media("<md") { 
                margin-top:30px;
                // transform:translateY(-40px);
                // margin-bottom:-40px;
            }

            .HomeSVG{
                position:fixed;
                top:40px;
                left:50%;
                transform:translateX(-50%);
                width:120vw;
                height:auto;
                z-index:-1;
                *{
                    fill:currentColor;
                }
            }
 
            aside{
                
                // width:35vw; 
                // display:flex;
                // flex-direction:row;  
                
                @include media(">sm") { 
                    // margin-bottom:70px; 
                }
                @include media("<md") { 
                    margin-bottom:30px;
                    width:100%;
                    .FlexWrap{
                        width:100%;
                    }
                    .LogoWrap{ 
                        display:flex;
                        flex-direction:row;
                        justify-content:space-between;
                        .Logo{
                            width:35vw;
                            max-width:120px;
                            height:auto;
                        }
                    }
                    ul{
                        font-size:rem(24);
                    }
                } // < md 

            } // aside


            section{ 

                @include media(">sm") { 
                    width:35vw; 
                }
                .ImgWrap{ 
                    @include media(">sm") { 
                       position:absolute;
                        top:0;
                        right:0;
                        width:35vw;
                        height:100vh;
                        min-height:100vh;
                        z-index:1003;
                    }
                    @include media("<md") { 
                       // display:none; 
                       position:relative;
                       height:calc((100vw - 60px) * 9 / 16);
                    }
                 }

                .Logo{ 
                    height:auto;
                    width:32.5vw;
                    position:fixed;
                    bottom:0px;
                    right:35vw;
                    z-index:-1; 
                    *{
                        fill:$graylighter;
                    }
                    @include media("<md") { 
                        display:none;
                    }
                    .Subtitle{
                        display:none;
                    } 
                } // logo

                @include media("<lg") { 
                    &.MobileCard{    
                        h1{
                            font-size:rem(54);
                        }                     
                        .InfoPanel{ 
                            // .Subtitle{
                            //     margin-bottom:30px;
                            // }
                        }
                    }
                }
                @include media("<md", ">xs") {
                    &.MobileCard{
                        margin-bottom:70px; 
                        .InfoPanel{
                            padding:30px;
                            .MobileArrow{
                                display:none;
                            }
                        }
                    }
                }
                @include media("<sm") { 
                    margin-bottom:70px;
                    &.MobileCard{ 
                        // margin-bottom:0.5rem;
                        h1{
                            font-size:rem(18);
                        }
                        .Subtitle{
                            font-size:rem(14);
                            span{
                                display:inline-block;
                                margin-right:0.5em;
                            }
                        }
                        .InfoPanel{
                            padding:15px;
                            *{
                                color:$white;
                            }
                        }
                        .MobileArrow{
                            position:absolute;
                            right:0;
                            top:50%;
                            transform:translateY(-50%);
                            color:$white;
                            svg{
                                height:1em;
                                width:auto;
                            }
                        }
                    }
                } // < md 

            } // section

            .InfoPanel{
 
                z-index:1004;
                display:flex;
                flex-direction:column;
                justify-content:space-between;

                @include media(">sm") { 
                    overflow:hidden;
                    position:absolute;
                    top:0px;
                    right:15vw; 
                    width:600px;
                } 
  
                h1{
                    font-size:rem(64);
                    letter-spacing: 0.1em;
                    @include media("<md") {
                        font-size:rem(40);
                    }  
                }
                .Upper{
                    line-height:1.25em;
                    letter-spacing: -0.025em;
                }
                .Cornered{
                    @include media(">md") {
                        max-width:50%;
                    }  
                }
                @include media("<lg") { 
                    width:50vw; 
                    right:5vw;
                }
                @include media("<md") {
                    width:100%;
                }
                    
            } // infopnel

            .Legend{

                position:absolute;
                bottom:0;
                left:0;
                z-index:2;
                width:100%;
                height:70px;
                display:flex;
                align-items:center;

                @include media("<md") { 
                    display:none;
                }

            } // Legend


            
            

        } // home display
 
 
        .HeroSection{ 

            min-height:55vh;

            display:flex;
            flex-direction:column;
            
            @include media("<md"){
                justify-content:flex-end; 
                align-items:center;
            }

            @include media("<lg"){
                margin-top:15px;
            }

            .InfoPanel{

                max-width:300px; 
                display:inline-flex;
                flex-direction:column; 
                align-items:center;
                box-shadow: 0 18px 44px 0 rgba(0,0,0,0.10);

                @include media(">md"){
                    margin-left:17vw;
                }
                h1{  
                    text-align:center; 
                    font-size:92px;  
                    @include media(">sm", "<lg"){ 
                        font-size:54px;
                    }
                    @include media("<md"){ 
                        font-size:48px;
                    }
                    @include media(">md"){
                        width:250%;
                    }
                    letter-spacing: 0.07em; 
                } // h1   
                p{
                    line-height:1.1;
                }
                @include media("<lg"){

                    max-width:calc(100% - 17vw);
                    width:calc(100% - 17vw); 
                     
                    @include media(">sm", "<lg"){
                        
                        // margin-bottom:17vw;
                    }

                    @include media("<md"){
                        max-width:calc(100%);
                        width:calc(100%);
                        // margin-bottom:30vw;
                    }

                    @include media(">sm", "<lg"){
                        transform:translate(-15px, -15px); 
                    }

                    @include media(">sm", "<lg"){
                        max-width:calc(100% - 30vw);
                        width:calc(100% - 30vw);
                        margin-bottom:30vw;
                        padding:60px 30px;
                    }

                     
                    
                } // lg

            } // InfoPanel

            .ThumbWrap{
                &:before{
                    z-index:1;
                    content:"";
                    position:absolute;
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    background:black;
                    opacity:0.07;
                } // before
            } // ThumbWrap
        } // HeroSection

        &.HeadingDecalage{
            &:before{
                content:"";
                display:inline-block;
                width:0.5em; 
            } // before
            &:after{
                content:"";
                display:inline-block;
                width:2em; 
            } // after
        } // HeadingDecalage

        .Cornered{

            position:relative; 

            &:before{
                content:"";
                top:-20px;
                left:-20px;
                position:absolute; 
                width:30px;
                height:30px;
                border:4px solid currentColor;
                border-right:0;
                border-bottom:0;
            } // before
            &:not(.Single){
                &:after{
                    content:"";
                    bottom:-20px;
                    right:-20px;
                    position:absolute; 
                    width:30px;
                    height:30px;
                    border:4px solid currentColor;
                    border-left:0;
                    border-top:0;
                }
            } // not single

        } // Cornered

        .BigShadow{  
            box-shadow: 30px 29px 84px 0 rgba(0,0,0,0.17);
        } // bigshadow

    // Aside

        aside{
            padding:0;
            margin:0; 
            ul{
                padding:0;
                margin:0;
                li{ 
                    margin-bottom:0.25em;
                    a{
                        &:not(:hover){
                            color:$black;
                        }
                    } // a
                    &:before{
                        display:none;
                    } // before
                } // li
            } // ul
        } // aside 
        
    // Img galleries

        .ImgGallery{

            display:flex;
            flex-direction:row-reverse;
            flex-wrap:wrap;
            align-items:flex-end;
            align-content:flex-end;
            margin-bottom:10vw;

            @include media("<md"){
                margin-bottom:30px;
            }  

            li{
                width:calc(50% - 12.5px);
                margin-bottom:25px;
                &:nth-child(even){
                    margin-right:25px;  
                } // Li impair 

            } // li
            &.Decale{
                margin-bottom:calc(25% + (25px / 2));
               li{
                    &:nth-child(even){
                        transform:translateY(calc(50% + (25px / 2))); 
                    } // Li impair 
               }
            }
            &.Cornered{
                &:before, &:after{
                    display:none;
                }
                li{
                    &:nth-child(even){
                        &:last-child{
                            &:before{
                                content:"";
                                position:absolute;
                                bottom:calc(0px - (25px / 2));
                                right:calc(0px - (25px / 2));
                                background:currentColor;
                                width:50%;
                                height:50%;
                                z-index:-1;
                            } // Yellow decoration
                        } // If the impair element is last
                    }
                    &:nth-child(1){
                        position:relative;
                        &:before{
                            content:"";
                            position:absolute;
                            top:calc(0px - (25px / 2));
                            left:calc(0px - (25px / 2));
                            background:currentColor;
                            width:50%;
                            height:50%;
                            z-index:-1;
                        } // Yellow decoration
                    } // On the second image
                }
            }

        } // Img Gallery
 

    .EqualBlock{
        @include media(">sm"){
            display:flex;
            flex-direction:row;
            >{
                flex-grow:1;
            }
        }
        @include media("<md"){
            [data-cover]{
                min-height:calc((100vw - 30px) * 9 / 16);
            }
        }
        
    }

    .FootLetter{

      overflow:hidden;

      &.E{
        .FootLetter-SVG{
            svg{
                position:absolute;
                right:3vw;
                bottom:-15vw;
                width:16vw!important;
                height:auto!important;
                @include media("<md"){
                    bottom:-15vw;
                    width:30vw!important;
                }
            }
        }
      } // E
      &.N{
        .FootLetter-SVG{
            svg{
                position:absolute;
                left:3vw;
                bottom:-10%; 
                height:120%!important;
                width:auto!important;
            }
        }
      } // N

    }// FootLetter

    .SectionLetter{
        &.SectionLetter-Left{
            svg{ left:-10vw; }
        }
        &.SectionLetter-Right{
            svg{ right:-10vw; }
        }
        .SectionLetter-SVG{
            svg{
                z-index:-1;
                position:absolute;
                
                top:2vw;
                width:40vw!important;
                height:auto!important;
            } 
        }
    }// FootLetter


    // Contact  

    .Coords{
        p{
            position:relative;
            z-index:2;
        }
        *[href^="tel:"]{
            letter-spacing:0.15em;
        }
        @include media(">xs"){
            &:before{
                content:""; 
                background:$black;
                top:0; 
                width:100%;
                height:100%;
                z-index:0;
                right:70px;
                position:absolute;
            }
        }
        @include media("<sm"){
            text-align:center;
            padding-left:15px;
        }
    } // Coords

    .ContactWrap{

        
        min-height:500px;  
        display:flex;
        flex-direction:column;
    
        @include media(">xs"){
            height:calc(100vh - 150px); 
        }
        @include media("<sm"){
            flex-wrap:wrap;
        }

        .Ornment{
            content:"";
            position:absolute;
            z-index:1;
            top:50%;
            left:-30px;
            width:60px;
            height:70vh;
            transform:translateY(-50%); 
            @include media("<sm"){
                display:none;
            }
        } // Ornment

        .MapWrap{

            flex-grow:1; 
            display:flex; 

            #map{
                flex-grow:1;  
            } 
            aside{
                width:350px; 
                @include media("<sm"){
                    text-align:center;
                    hr{
                        margin-left:auto;
                        margin-right:auto;
                    }
                }
            }

            @include media("<sm"){
                //flex-wrap:wrap;
                flex-wrap: wrap;
                #map{
                    order:2;
                    min-height:400px;
                }
                #map, aside{
                    width:100%;
                }
            }

        } // MapWrap

    } // ContactWrap

    .HeadingContact{
        @include media(">xs"){
            font-size:rem(76);
            width:600px;
            display:inline-block;
            letter-spacing:0.10em;
            text-align:right;
            line-height:1.15em;
            position:absolute;
            top:calc(0px - 1.85em);
            right:50%;
            z-index:1; 
            &:before{
                content:"";
                width:1.2em;
                height:7px;
                background-color:currentColor;
                position:absolute;
                top:50%;
                right:-20px;
                transform:translateY(calc(-50% + 0.1em));
                z-index:1;
            }
            &:after{
                content:"";
                width:0.2em;
                display:inline-block; 
            }
        } // > xs
        @include media("<sm"){
            text-align:center;
            margin-bottom:30px;
        }
    } // HeadingContact

    .BackArrow{
        position:absolute;
        top:50%;
        left:0;
        transform:translateY(-50%);
        @include media("<xs"){
            display:none;
        }
    }

    .Members{
        .Heading{
            @include media("<lg"){
                display:block;
                h1{
                    margin-bottom:0.5em;
                }
            }
        }
        .MembersCard{
            h2{
                @include media("<sm"){
                    font-size:rem(24);
                }
            }
        }
    }

    .HeadingMembers{
        
        display:inline-block;
         
        @include media("<md"){
            font-size:rem(50); 
            margin:0;
            @include media("<sm"){
                font-size:rem(36)!important;
            }
        }

        @include media("<sm"){
            text-align:center;
            em{
                display:block;
                font-size:0.8em;
            }
        }
        
        @include media(">xs"){

            font-size:rem(76); 
            letter-spacing:0.10em;
            line-height:1.15em; 

            em{
                display:block;
                position:absolute;
                z-index:-1;
                top:50%;
                left:calc(50% - 45px);
                transform:translate(-50%, -50%);
                letter-spacing: 0.05em;
                width:calc(100vw - 400px)!important;
                @include media(">sm", "<lg"){
                    font-size:rem(60); 
                    width:calc(100vw - 300px)!important;
                }
                @include media(">xs", "<md"){
                    font-size:rem(50); 
                    width:calc(100vw - 200px)!important;
                }
                opacity:0.2;
            }
            span{ 
                display:block;
                transform:translateX(-90px);
                position:relative;
                &:before{
                    content:"";
                    position:absolute;
                    top:100%;
                    left:1em;
                    height:7px;
                    width:1.2em;
                    background-color:currentColor;
                    z-index:1;
                }
            } // span
       }

    } // HeadingMembers

    // Members

    .LeftSvg{
        position:fixed;
        pointer-events:none;
        top:50%;
        transform:translateY(-50%);
        left:-10vw; 
        z-index:-10; 
        svg{
            width:auto;
            height:900px;
        }
    } // LeftSvg

    .RightSvg{
        position:fixed;
        pointer-events:none;
        top:50%;
        transform:translateY(-50%);
        right:-10vw; 
        z-index:-10; 
        svg{
            width:auto;
            height:900px;
        }
    } // RightSvg

    

    // -----------------------------------
    // MembersList
    // Shared styles betwee Free & Prenium
    // -----------------------------------

    // List of letters to filter the members list

    .Alphabet{
        &:hover{
            > *{
                opacity:0.2;
                &:hover, &.active{
                    opacity:1;
                }
            }
        }
        > *{ 
            padding:0 0.75vw;
            &.active, &:hover{
                span{
                    cursor:pointer;
                    position:relative; 
                    &:before{
                        content:"";
                        position:absolute;
                        z-index:1;
                        top:-0.25em;
                        left:0;
                        height:2px;
                        width:100%;
                        background-color:currentColor;
                    }
                    &:after{
                        content:"";
                        position:absolute;
                        z-index:1;
                        bottom:-0.25em;
                        left:0;
                        height:2px;
                        width:100%;
                        background-color:currentColor;
                    }
                }
            } // active
        } // > *
    } // Alphabet

    // The memberslist itself

    .MembersList{
 
        ul{
            display:flex;
            flex-direction:row; 
            flex-wrap:wrap; 
            position:relative;
        } // ul
         
        &:not(:first-child){
            display:none;
        }
        
        li{ 
            display:inline-flex;
            flex-direction:column;
            a{ 
                position:relative;
            }
            .Name{
                letter-spacing:0.15em;
            }
            .Type{
                letter-spacing: 0.1em;
                flex-grow:1;
            }
            .Email{
                letter-spacing:0.1em;
            }  
        } // li

        // -------------------------
        // MembersList - Free
        // -------------------------

        &:not(.MembersList_BSL){
            
            // Member tile

            li{
                
                // Closing the grid
                border-bottom:1px solid $graylight; 
                width:50%;
                @include media("<sm"){
                    width:100%;
                }

                 
                // Styling of the grid, little selector magic
                // to avoid visual glitches of the grid, 
                // no matter how many results where filtered.

                @include media(">xs"){

                    &:nth-last-child(-n+2):not(:nth-child(even)), &:last-child{
                        border-bottom:none;
                    } 
                    &:nth-child(odd):not(:last-child){
                        border-right:1px solid $graylight;
                        padding-right:50px;
                    } 
                    &:nth-child(odd):last-child:not(:first-child){
                        border-right:1px solid $graylight; 
                    }
                    &:nth-child(even){
                        padding-left:50px;
                    } 

                }
                    
                // When hovering a tile, display an arrow
                // and change color

                &:hover{
                    color:$blue;
                    .Arrow{
                        display:block;
                        opacity:1;
                    }
                } // hover

                    .Arrow{
                        display:inline-block; 
                        pointer-events:none;
                        opacity:0; 
                        position:absolute;
                        top:50%;
                        transform:translateY(-50%);
                        right:1em;
                        z-index:-1; 
                        color:$graylightest;
                        svg{
                            height:85px;
                            width:auto;
                        }
                    } // Arrow 

            } // li

        } // MembersList - Free
        
        // -------------------------
        // MembersList - Premium
        // -------------------------

        &.MembersList_BSL{
            
            // Center the tiles horizontally, 
            // when the line is not complete

            ul{
                justify-content:center; 
            }
            
            // BG Holder

            .MemberBG{
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                opacity:1;  
                pointer-events:none; 
                overflow:hidden; 
                .BG{
                    // Animation
                        transition:all 0.3s .0s;
                    // - 
                    opacity:0;
                    left:0%;
                    top:0%;
                    transform-origin:center center;
                    transform:scale(1.025);
                } // BG
            } // MemberBG 
                
            // Hovering the whole block 
            // (the whole members list)

            &:hover{ 
                
                // Interaction of all members

                li{
                    
                    // Reduce all members type
                    // when image is present

                    a{
                        *:not(.Overlay){
                            opacity:0.15;
                            transition:opacity .2s;
                        }  
                    } // a

                    // Hide grid when image is present

                        transition:all .2s 0s;
                        border-top:1px solid rgba(219, 219, 219, 0);
                        border-right:0.5px solid rgba(219, 219, 219, 0);   
                        a{ 
                            &:before{ 
                                transition:all .2s 0s;
                                background:rgba(219, 219, 219, 0); 
                            } // before
                        } // a
                        &:nth-child(4n+1){
                            a{
                                &:after{ 
                                    transition:all .2s 0s;
                                    background:rgba(219, 219, 219, 0);
                                } // after
                            } // a
                        } // nth-child 4n + 1


                    // When hovering the list, everything's faded
                    // except the hovered member, which is enlightened

                    &:hover{ 
                        
                        // Display blue tile
                        a{
                            z-index:1;
                            color:$white;
                            *:not(.Overlay){
                                opacity:1;
                                transition:none;
                            }
                             .Overlay{
                                opacity:1;
                            }
                        } // a

                        // Display background only over XS

                        @include media(">xs"){ 
                            .MemberBG{
                                .BG{
                                    // Animation
                                        transition:all 1s .1s;
                                    // - 
                                    opacity:1;
                                    left:0%;
                                    top:0%;
                                    transform-origin:center center;
                                    transform:scale(1);
                                } // BG
                            } // MemberBG 
                        }   

                    } // hover

                } // li
            } // hover
                
            // Global styles for the tiles

            li{
                
                // Animate the grid for it to disapear 
                transition:all .8s 0.1s;
                // *
                border-top:1px solid rgba(219, 219, 219, 1);
                border-right:0.5px solid rgba(219, 219, 219, 1); 
                position:static; 
                
                @media screen and (min-width:600px) and (max-width:768px){ 
                    width:50%;   
                }
 
                a{
                    position:relative;
                    .Overlay{
                        position:absolute;
                        top:-1px;
                        left:-1px;
                        width:calc(100% + 2px);
                        height:calc(100% + 2px);
                        background:$blue;
                        color:$white; 
                        box-shadow: 0 2px 34px 0 rgba(0,0,0,0.30);
                        opacity:0;
                    } // Overlay
                    &:before{
                        // Animate the grid for it to disapear
                        transition:all .8s 0.1s;
                        // *
                        content:"";
                        position:absolute;
                        top:100%;
                        left:0;
                        width:100%;
                        height:1px;
                        background:rgba(219, 219, 219, 1);
                    } // before

                } // a

                // To complete the grid and avoid display glitches

                &:nth-child(4n+1){
                    a{
                        &:after{
                            // Animate the grid for it to disapear                            
                            transition:all .8s 0.1s;
                            // *
                            content:"";
                            position:absolute;
                            top:0;
                            left:0;
                            width:1px;
                            height:100%;
                            background:rgba(219, 219, 219, 1);
                        } // after
                    } // a
                } // nth-child 4n + 1

            } // li

        } // MemberList_BSL

    } // MembersList


    // EventsLists


    .Events{
    
        .HeadingEvents{
            h1{
                font-size:rem(48);
                em{
                    margin-top:0.3em;
                    font-size:rem(70);
                }
                @include media("<sm"){
                    font-size:rem(32);
                    em{
                        font-size:rem(56);
                    }
                }
            }
        }  
        .EventsList{
            background-position:fixed;
            // background-size:100vh;
            background-image: linear-gradient(-180deg, #000000 0%, #2E2E2E 100%);
        }
        .BackArrow{
            z-index:11;
        }

        .LeftSvg{
            z-index:10;
            left:-14vw;
            pointer-events:none;
            @include media("<md"){
                left:-30vw;
                z-index:-1;
            }
            @include media(">sm", "<lg"){
                left:-25vw;
              //  z-index:-1;
            }
        }

        .Month{
            .Date{
                font-size:rem(56);
                @include media("<xs"){
                    text-align:center;
                    font-size:rem(44);
                }
            }
            h2{
                font-size:rem(32);
                @include media("<sm"){ 
                    font-size:rem(24);
                }
                letter-spacing:0.05em;
                position:relative;
                z-index:1;
            }
            h3{
                font-size:rem(20);
            }
            .Period{
                font-size:rem(16);
            }
            .Event{
                &:not(:last-child){
                    border-bottom:1px solid rgba(151, 151, 151, 0.3);
                } 
                .Arrow{
                    position:absolute;
                    top:50%;
                    right:0;
                    transform:translate(-70px, -50%);
                    opacity:0;
                    transition:all .2s;
                    z-index:0;
                    svg{
                        height:70px;
                        width:auto;
                    }
                } 
                &:hover{
                    .Arrow{
                        transform:translate(0px, -50%);
                        opacity:1;
                    }
                    .BG{
                        // Animation
                            transition:all 0.3s .1s;
                        // -  
                        left:0%;
                        top:0%;
                         opacity:0.2!important; 
                        transform-origin:center center;
                        transform:scale(1);
                    } // BG 
                }
            }
        } // Month

        .PhotoHolder{ 
            position:fixed;
            top:0;
            right:0;
            width:50%;
            height:100%;
            z-index:1;
           
            pointer-events:none; 
            overflow:hidden;
            .BG{
                filter: grayscale(100%);
                // Animation
                    transition:all 0.3s .0s;
                // - 
                opacity:0;
                left:0%;
                top:0%;
                transform-origin:center center;
                transform:scale(1.025);
            } // BG
            
        }

    } // Events
